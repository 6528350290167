input, label{
    display: block;
    margin-bottom: 1em;
    height: 1.25rem;
}
/* input:focus{
    background-color: #FCC;
} */
/* this is a terrible idea with react */
/* the component has no idea what's going on */
button{
    width:8rem;
    height: 2rem;
    background-color: lightgreen;
    border-radius: 0.25rem;
    margin: auto;
}
.error{
    width:8rem;
    height: 2rem;
    background-color: #FCC;
    color: darkred;
    font-size: 0.75rem;
    line-height: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0rem 0rem 0.25rem 0.25rem;
    border-top: 4px solid darkred;
}
/* .errorBckgrd{
    background-color: #FCC;
    color: darkred;
} */
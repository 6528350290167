.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-bottom: 6em;
}

.App-link {
  color: #09d3ac;
}

.notes{
  font-size: 0.75rem;
  color: #999;
  margin-bottom: 2rem;
}

.App-header button{
  margin:2em 0em;
}
